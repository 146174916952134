import { useTranslation } from "react-i18next";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

import {
  iWallSlot,
  WallSlotTypePayload,
  WallSlotPromo,
} from "../../../../config/wall";
import Icon from "../Icon/Icon";
import { fireTagManagerEvent } from "../../../Services/tagmanager";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../Utils/generic";

interface Props {
  slot?: iWallSlot;
}

export default function PromoContainer(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  if (props.slot?.config?.type) {
    const promoConfig = props.slot?.config as WallSlotTypePayload;
    const payload = promoConfig.payload as WallSlotPromo;

    return (
      <div
        className="text-white p-2 cursor-pointer"
        style={{ margin: isSmallScreen ? "10px 0" : "40px 0" }}
        onClick={() => {
          fireTagManagerEvent("click_banner", {
            url: payload.action ?? "",
          });
          window.open(payload.action ?? "", "_blank");
        }}
      >
        {/* <h2 className="h1 regular m-0">{t(props.slot?.title ?? "")}</h2> */}
        {/* <p>{t(props.slot?.subtitle ?? "")}</p> */}
        <div className="d-flex justify-content-center">
          <div className="d-inline-flex flex-wrap"></div>
        </div>
        <div
          className="panel p-0 h-small small medium"
          style={{ minHeight: "400px" }}
        >
          <div
            className="panel-bg"
            style={{
              backgroundImage: `url(${payload?.background ?? ""})`,
              backgroundSize: "cover",
            }}
          ></div>
          <div className="panel-shadow"></div>
          <div
            className="panel-header bg-white text-black gap-4 d-flex"
            style={{
              padding: isSmallScreen ? "20px 20px" : "20px 40px",
              display: "flex",
            }}
          >
            {!isSmallScreen && (
              <img
                src={payload?.logo ?? ""}
                style={{
                  maxHeight: "64px",
                  width: "150px",
                }}
              />
            )}
            <div className="d-flex justify-content-center align-items-center gap-2">
              <p className="fs-h3 medium">{t(payload?.title) ?? ""}</p>
              <Icon
                icon="arrow_right_alt"
                size={isSmallScreen ? 64 : 32}
              ></Icon>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
