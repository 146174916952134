export default function SelectableButton({
  selected,
  onClick,
  children,
}: {
  selected: boolean;
  onClick: () => void;
  children: JSX.Element | string;
}) {
  return (
    <div
      style={{
        padding: "8px 16px",
        border: "1px solid lightgray",
        borderRadius: "8px",
        opacity: selected ? 1 : 0.4,
      }}
      className="cursor-pointer bodytext"
      onClick={onClick}
    >
      {children}
    </div>
  );
}
