import axios from "axios";
import iCollection from "../models/iCollection";
import { iPagination, iPaginationProps } from "../models/iPagination";
import { getAmountOfNights } from "../Utils/generic";

export interface Destination {
  dest_id: string;
  search_type: string;
  image_url: string;
  country: string;
  label: string;
  region: string;
  type: string;
  nr_hotels: number;
  name: string;
  latitude: number;
  cc1: string;
  hotels: number;
  dest_type: string;
  city_name: string;
  lc: string;
  roundtrip: string;
  longitude: number;
  city_ufi: string | null;
}

export interface SearchParameters {
  certified_only?: string;
  includes_benefits?: string;

  dest_id?: string;

  latitude?: string;
  longitude?: string;
  radius?: string;

  dest_label?: string;
  search_type?: string;
  arrival_date?: string;
  departure_date?: string;

  adults?: string;
  children_age?: string;
  room_qty?: string;
  page_number?: string;
  price_min?: string;
  price_max?: string;
  units?: string;
  temperature_unit?: string;
  languagecode?: string;
  currency_code?: string;
  sort_by?:
    | "upsort_bh"
    | "popularity"
    | "distance"
    | "closest_beach_distance"
    | "class_descending"
    | "bayesian_review_score"
    | "price"
    | "class_ascending";
  categories_filter?: string;
  // price::x-y
  // Breakfast included - mealplan::breakfast_included
  // Very good: 8+ - reviewscorebuckets::80
  // Airport shuttle - facility::17
  // Andheri - district::3712
  // Good: 7+ - reviewscorebuckets::70
  // Colaba - district::3448
  // Hotels - property_type::204
  // South Mumbai - district::2379
  // Passable: 5+ - reviewscorebuckets::50
  // Pleasant: 6+ - reviewscorebuckets::60
  // Good: 7+ - reviewscorebuckets::70
  // Very good: 8+ - reviewscorebuckets::80
  // Superb: 9+ - reviewscorebuckets::90
  // Hotels - property_type::204
  // Entire homes & apartments - privacy_type::3
  // Apartments - property_type::201
  // Hostels - property_type::203
  // Guest houses - property_type::216
  // Villas - property_type::213
  // Homestays - property_type::222
  // Bed and breakfasts - property_type::208
  // Capsule hotels - property_type::225
  // Resorts - property_type::206
  // Holiday homes - property_type::220
  // Farm stays - property_type::210
  // Lodges - property_type::221
  // Love hotels - property_type::226
  // Courtyard by Marriott - chaincode::1093
  // FabHotels - chaincode::6129
  // Fairfield Inn - chaincode::2188
  // Four Seasons Hotels and Resorts - chaincode::2402
  // Grand Hyatt - chaincode::3630
  // Hilton Hotels & Resorts - chaincode::1078
  // Holiday Inn Hotels & Resorts - chaincode::1072
  // ibis - chaincode::1053
  // InterContinental Hotels & Resorts - chaincode::1070
  // JW Marriott Hotels & Resorts - chaincode::1097
  // Luxury Collection - chaincode::12897
  // Marriott Executive Apartments - chaincode::4370
  // Marriott Hotels & Resorts - chaincode::1080
  // Novotel - chaincode::1050
  // Oberoi Hotels & Resorts - chaincode::2101
  // OYO Rooms - chaincode::6015
  // Ramada - chaincode::1045
  // Royal Orchid Hotels - chaincode::2133
  // Sarovar (Louvre) - chaincode::14500
  // Sofitel - chaincode::1049
  // StayVista - chaincode::11573
  // The Indian Hotels Co Ltd - chaincode::2020
  // The Leela - chaincode::3422
  // Treebo Hotels - chaincode::5975
  // Westin - chaincode::12898
  // Free WiFi - facility::107
  // Room service - facility::5
  // 24-hour front desk - facility::8
  // Non-smoking rooms - facility::16
  // Parking - facility::2
  // Family rooms - facility::28
  // Free parking - facility::46
  // Restaurant - facility::3
  // Airport shuttle - facility::17
  // Pets allowed - facility::4
  // Swimming Pool - facility::433
  // Wheelchair accessible - facility::185
  // Fitness centre - facility::11
  // Spa and wellness centre - facility::54
  // Electric vehicle charging station - facility::182
  // BBQ facilities - facility::72
  // Airport shuttle (free) - facility::139
  // Less than 1 km - distance::1000
  // Less than 3 km - distance::3000
  // Less than 5 km - distance::5000
  // Gateway of India - popular_nearby_landmarks::202948
  // Juhu Beach - popular_nearby_landmarks::261342
  // Marine Drive - popular_nearby_landmarks::15060
  // NITIE - popular_nearby_landmarks::900066749
  // Viviana Mall - popular_nearby_landmarks::900120953
  // Western Suburbs - district::3287
  // Andheri - district::3712
  // South Mumbai - district::2379
  // Bandra - district::3445
  // Bandra Kurla Complex - district::3446
  // Borivali  - district::3605
  // CBD Belapur - district::8667
  // Central - district::2559
  // Central Suburbs - district::2558
  // Churchgate - district::3451
  // Colaba - district::3448
  // Dadar - district::3442
  // Grant Road - district::17337
  // Juhu - district::2375
  // Kemps Corner - district::7365
  // Khar - district::8663
  // Kurla - district::17020
  // Lower Parel - district::8424
  // Malabar Hill - district::17307
  // Mumbai Historical And Heritage - district::17444
  // Nariman Point - district::3447
  // Powai - district::8426
  // Santacruz - district::3619
  // Vashi - district::8666
  // Vikhroli - district::17026
  // Worli - district::3449
  // Unrated - class::0
  // 1 star - class::1
  // 2 stars - class::2
  // 3 stars - class::3
  // 4 stars - class::4
  // 5 stars - class::5
  // 2 single beds - twin_double_bed::2
  // Double bed - twin_double_bed::3
  // Electric kettle - room_facility::86
  // View - room_facility::81
  // Soundproofing - room_facility::79
  // Flat-screen TV - room_facility::75
  // Bath - room_facility::5
  // Desk - room_facility::23
  // Air conditioning - room_facility::11
  // Private bathroom - room_facility::38
  // Free cancellation - free_cancellation::1
  // 1+ bedrooms - entire_place_bedroom_count::1
  // 2+ bedrooms - entire_place_bedroom_count::2
  // 3+ bedrooms - entire_place_bedroom_count::3
  // 4+ bedrooms - entire_place_bedroom_count::4
  // Breakfast included - mealplan::breakfast_included
  // Breakfast & dinner included - mealplan::breakfast_and_dinner
  // All-inclusive - mealplan::all_inclusive
  // Breakfast & lunch included - mealplan::breakfast_and_lunch
  // All meals included - mealplan::full_board
  // Self catering - mealplan::999
  // All deals - deal::7
}

export interface Hotel {
  hotel_id: number; // in our database is saved as a string
  accessibilityLabel: string;
  property: {
    checkin: {
      untilTime: string;
      fromTime: string;
    };
    rankingPosition: number;
    accuratePropertyClass: number;
    countryCode: string;
    position: number;
    optOutFromGalleryChanges: number;
    id: number;
    reviewCount: number;
    priceBreakdown: {
      taxExceptions: any[];
      benefitBadges: {
        identifier: string;
        text: string;
        explanation: string;
        variant: string;
      }[];
      excludedPrice: {
        currency: string;
        value: number;
      };
      strikethroughPrice: {
        value: number;
        currency: string;
      };
      grossPrice: {
        currency: string;
        value: number;
      };
    };
    longitude: number;
    latitude: number;
    checkoutDate: string; // Format: YYYY-MM-DD
    name: string;
    isPreferred: boolean;
    wishlistName: string;
    checkout: {
      untilTime: string;
      fromTime: string;
    };
    mainPhotoId: number;
    isFirstPage: boolean;
    checkinDate: string; // Format: YYYY-MM-DD
    photoUrls: string[];
    reviewScoreWord: string;
    blockIds: string[];
    currency: string;
    ufi: number;
    propertyClass: number;
    reviewScore: number;
    qualityClass: number;
  };

  _collection?: iCollection;
}

export interface Review {
  date: string;
  pros_translated: string;
  author: {
    avatar: string;
    type_string: string;
    helpful_vote_count: number;
    name: string;
    city: string;
    countrycode: string;
    type: string;
    nr_reviews: number;
    user_id: number;
  };
  review_hash: string;
  title_translated: string;
  review_id: number;
  countrycode: string;
  hotelier_response_date: number;
  reviewng: number;
  cons: string;
  anonymous: string;
  stayed_room_info: {
    room_name: string;
    checkout: string;
    room_id: number;
    num_nights: number;
    checkin: string;
  };
  hotel_id: number;
  tags: string[];
  languagecode: string;
  title: string;
  average_score: number;
  cons_translated: string;
  is_incentivised: number;
  hotelier_response: string;
  user_new_badges: string[];
  no_user_title: number;
  reviewer_photos: string[];
  pros: string;
  travel_purpose: string;
  is_moderated: number;
  helpful_vote_count: number;
}

interface getPaginatedProps extends iPaginationProps {
  bquery: string;

  // {
  //   arrival_date: string;
  //   departure_date: string;

  //   units?: string;
  //   temperature_unit?: string;
  //   languagecode?: string;
  //   currency_code?: string;
  // };
}

export async function getCollectionsPaginatedWithBookingComData(
  params: getPaginatedProps,
): Promise<iPagination<iCollection>> {
  const response = await axios.get("/metasearch/paginate", {
    params,
  });
  return response.data;
}

export async function BookingComSearch(
  searchParams: SearchParameters,
): Promise<Hotel[]> {
  const params = { ...searchParams };
  const hasPriceMin = Boolean(params.price_min);
  const hasPriceMax = Boolean(params.price_max);
  const nights = getAmountOfNights(params.arrival_date, params.departure_date);
  if (hasPriceMin)
    params.price_min = Math.round(Number(params.price_min) * nights).toFixed(0);

  if (hasPriceMax)
    params.price_max = Math.round(Number(params.price_max) * nights).toFixed(0);

  const res = await axios.post("/metasearch/hotels", params);

  return res.data;
}

export async function BookingComReviews(params: {
  hotel_id: string;
  languagecode?: string;
}): Promise<Review[]> {
  const res = await axios.post("/metasearch/reviews", params);
  return res.data;
}

export async function BookingComSearchRegions(params: {
  query: string;
}): Promise<Destination[]> {
  const res = await axios.post("/metasearch/regions", params);
  return res.data;
}

export async function getCheckoutLink(params: {
  collectionId: string;
  searchParameters: SearchParameters;
}): Promise<string> {
  const res = await axios.post("/metasearch/checkout", params);
  return res.data;
}
