import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/img/icons/TakyonDark.svg";
import { useSearchParams } from "react-router-dom";
import { getCheckoutLink } from "../../../assets/Services/metasearch";

export default function MetasearchRedirect() {
  const [urlParams] = useSearchParams();
  const [url, setUrl] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    // Convert URLSearchParams to a key-value object
    const paramsObject = Object.fromEntries(urlParams.entries());
    const { collectionId, fallbackUrl, ...searchParameters } = paramsObject;

    if (collectionId) {
      getCheckoutLink({
        collectionId,
        searchParameters,
      }).then((url) => {
        const redirect = url ?? fallbackUrl;
        setUrl(redirect);
        window.location.href = redirect;
      });
    } else {
      setUrl(fallbackUrl);
      setTimeout(() => {
        window.location.href = fallbackUrl;
      }, 3000);
    }
  }, [urlParams]);

  return (
    <div
      style={{
        background: "white",
        height: "100svh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img style={{ height: "40px" }} src={logo} alt="" />
      <br />
      <br />
      <div className="p-3" style={{ maxWidth: "700px" }}>
        <p className="text-center h3 medium">{t("ds.redirect_title")}</p>
        <div className="height-12"></div>
        <p className="text-center h3 dark-grey-color light">
          {t("ds.redirect_subtitle")}
        </p>
        <div className="height-25"></div>
        <p className="text-center h3 dark-grey-color light">
          {t("ds.redirect_cta1")} <a href={url}>{t("ds.redirect_cta2")}</a>
        </p>
      </div>
    </div>
  );
}
