import { useTranslation } from "react-i18next";
import { SearchParameters } from "../../../assets/Services/metasearch";
import { useMediaQuery } from "react-responsive";
import { SMALL_SCREEN } from "../../../assets/Utils/generic";
import { useCallback, useEffect, useMemo, useState } from "react";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Slider from "../../../assets/TakyonDesignSystem/components/Slider/Slider";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import { facility } from "./FilterTags";
import SelectableButton from "../../../assets/TakyonDesignSystem/components/Button/SelectableButton";
import { MAX_PRICE, MIN_PRICE } from "./Search";

/**
 * Replaces all the filters that match the query with the provided value
 * @return the modified parameters object
 */
export function updateCategoryFilter(
  query: string,
  value: string,
  currentFilters: SearchParameters,
  exactMatch: boolean = false,
): SearchParameters {
  const cat = currentFilters.categories_filter ?? "";

  const match = (tag: string, query: string) =>
    exactMatch ? tag === query : tag.includes(query);

  const catArray = cat
    .split(",")
    // Remove the tag if the value is empty
    .filter((tag) => !(match(tag, query) && value === ""))
    .map((tag) => {
      if (!match(tag, query)) return tag; // Keep the tag if it doesn't match the query
      return value; // Replace the tag if it matches the query
    });

  // Add the tag if it doesn't exist and the value is not empty
  if (value !== "" && !catArray.some((tag) => match(tag, query))) {
    catArray.push(value);
  }

  return {
    ...currentFilters,
    categories_filter: catArray.filter((e) => e).join(","),
  };
}

function FacilitiesSection({
  filters,
  setFilters,
}: {
  filters: SearchParameters;
  setFilters: any;
}) {
  const { t } = useTranslation();
  const facilities = useMemo(
    () => filters.categories_filter?.match(/hotelfacility::(\d+)/g),
    [filters],
  );

  return (
    <>
      <p className="h3 medium">{t("ds.facilities")}</p>
      <div className="height-16"></div>
      <div className="d-flex gap-2 flex-wrap">
        {Object.entries(facility).map(([num, type]) => (
          <SelectableButton
            selected={!!facilities?.includes(`hotelfacility::${num}`)}
            onClick={() => {
              const filter = `hotelfacility::${num}`;
              if (!facilities?.includes(filter))
                setFilters(updateCategoryFilter(filter, filter, filters, true));
              else setFilters(updateCategoryFilter(filter, "", filters, true));
            }}
          >
            {t(`facilities.${type}`)}
          </SelectableButton>
        ))}
      </div>
    </>
  );
}

export default function FiltersModal({
  onFilter,
  searchParameters,
}: {
  onFilter(filter: SearchParameters): void;
  searchParameters: SearchParameters;
}) {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  const [filters, setFilters] = useState<SearchParameters>(searchParameters);

  const [sliderMin, setSliderMin] = useState<number>(
    Number(searchParameters.price_min) || MIN_PRICE,
  );
  const [sliderMax, setSliderMax] = useState<number>(
    Number(searchParameters.price_max) || MAX_PRICE,
  );

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      price_min: sliderMin.toFixed(0),
    }));
  }, [sliderMin]);

  useEffect(() => {
    setFilters((f) => ({
      ...f,
      price_max: sliderMax.toFixed(0),
    }));
  }, [sliderMax]);

  const priceFilterValidation = useCallback(() => {
    if (sliderMin < MIN_PRICE) setSliderMin(MIN_PRICE);
    if (sliderMax > MAX_PRICE) setSliderMax(MAX_PRICE);
    if (sliderMax < sliderMin) {
      setSliderMin(MIN_PRICE);
      setSliderMax(MAX_PRICE);
    }
  }, [sliderMin, sliderMax]);

  return (
    <>
      <div
        style={{ padding: "24px 32px", overflow: "scroll" }}
        className="w100"
      >
        <p className="h3 medium">{t("ds.most_used_filters")}</p>
        <div className="height-16"></div>
        <div style={{ gap: "12px" }} className="d-flex justify-content-around">
          <div
            style={{
              aspectRatio: "1/1",
              overflow: "hidden",
              width: isSmallScreen ? "33.33%" : "20%",
              border: "1.5px solid black",
              opacity: filters.certified_only ? 1 : 0.3,
              userSelect: "none",
            }}
            onClick={() => {
              if (!filters.certified_only)
                setFilters({ ...filters, certified_only: "true" });
              else setFilters({ ...filters, certified_only: undefined });
            }}
            className="d-flex align-items-center justify-content-center flex-column rounded p-2 cursor-pointer"
          >
            <IconNew icon="check_circle" />
            <div className="height-8"></div>
            <span className="bodytext-lg text-center">
              {t("ds.certified_only")}
            </span>
          </div>
          <div
            style={{
              aspectRatio: "1/1",
              overflow: "hidden",
              width: isSmallScreen ? "33.33%" : "20%",
              border: "1.5px solid black",
              opacity: filters.includes_benefits ? 1 : 0.3,
              userSelect: "none",
            }}
            onClick={() => {
              if (!filters.includes_benefits)
                setFilters({ ...filters, includes_benefits: "true" });
              else setFilters({ ...filters, includes_benefits: undefined });
            }}
            className="d-flex align-items-center justify-content-center flex-column rounded p-2 cursor-pointer"
          >
            <IconNew icon="icon__family_friendly" />
            <div className="height-8"></div>
            <span className="bodytext-lg text-center">
              {t("ds.includes_benefits")}
            </span>
          </div>
          <div
            style={{
              aspectRatio: "1/1",
              overflow: "hidden",
              width: isSmallScreen ? "33.33%" : "20%",
              border: "1.5px solid black",
              opacity: filters.categories_filter?.includes(
                "reviewscorebuckets::70",
              )
                ? 1
                : 0.3,
              userSelect: "none",
            }}
            onClick={() => {
              if (
                !filters.categories_filter?.includes("reviewscorebuckets::70")
              ) {
                setFilters(
                  updateCategoryFilter(
                    "reviewscorebuckets::70",
                    "reviewscorebuckets::70",
                    filters,
                  ),
                );
              } else {
                setFilters(
                  updateCategoryFilter("reviewscorebuckets::70", "", filters),
                );
              }
            }}
            className="d-flex align-items-center justify-content-center flex-column rounded p-2 cursor-pointer"
          >
            <IconNew icon="star_fill" />
            <div className="height-8"></div>
            <span className="bodytext-lg text-center">
              {t("ds.minimum_score")}
            </span>
          </div>
        </div>

        <div className="horizontal-divider" style={{ margin: "26px 0" }}></div>

        <p className="h3 medium">{t("ds.price_filter")}</p>
        <div className="height-26"></div>

        <Slider
          min={MIN_PRICE}
          max={MAX_PRICE}
          onChange={(min, max) => {
            setSliderMin(min);
            setSliderMax(max);
          }}
          onChangeEnd={() => {}}
          values={{
            min: sliderMin,
            max: sliderMax,
          }}
        />
        <div className="height-26"></div>

        <div className="d-flex gap-3 justify-content-between">
          <div>
            <span className="bodytext">{t("ds.filter_price_min")}</span>
            <input
              onChange={(e) => {
                const num = Number(e.target.value.replace("€", ""));
                setSliderMin(isNaN(num) ? MIN_PRICE : num);
              }}
              onBlur={priceFilterValidation}
              type="text"
              value={"€" + sliderMin.toFixed(0)}
            />
          </div>
          <div>
            <span className="bodytext">{t("ds.filter_price_max")}</span>
            <input
              onChange={(e) => {
                const num = Number(e.target.value.replace("€", ""));
                setSliderMax(isNaN(num) ? MAX_PRICE : num);
              }}
              onBlur={priceFilterValidation}
              type="text"
              value={"€" + sliderMax.toFixed(0)}
            />
          </div>
        </div>

        <div className="horizontal-divider" style={{ margin: "26px 0" }}></div>

        <p className="h3 medium">{t("ds.score_filter")}</p>
        <div className="height-16"></div>

        <div className="d-flex flex-wrap gap-3">
          {[
            "reviewscorebuckets::90",
            "reviewscorebuckets::80",
            "reviewscorebuckets::70",
            "reviewscorebuckets::60",
          ].map((score) => {
            return (
              <SelectableButton
                selected={!!filters.categories_filter?.includes(score)}
                onClick={() => {
                  if (!filters.categories_filter?.includes(score))
                    setFilters(
                      updateCategoryFilter(
                        "reviewscorebuckets",
                        score,
                        filters,
                      ),
                    );
                  else
                    setFilters(
                      updateCategoryFilter("reviewscorebuckets", "", filters),
                    );
                }}
              >
                <>
                  <IconNew icon="star_fill" />
                  <span>{t("ds.score_" + score.split("::")[1])}</span>
                </>
              </SelectableButton>
            );
          })}
        </div>

        <div className="horizontal-divider" style={{ margin: "26px 0" }}></div>

        <p className="h3 medium">{t("ds.distance_filter")}</p>
        <div className="height-16"></div>

        <div className="d-flex gap-2 align-items-center bodytext-lg">
          <div>
            <input
              id="distance_1"
              type="checkbox"
              checked={filters.categories_filter?.includes("distance::1000")}
              onChange={() => {
                if (filters.categories_filter?.includes("distance::1000"))
                  setFilters(updateCategoryFilter("distance", "", filters));
                else
                  setFilters(
                    updateCategoryFilter("distance", "distance::1000", filters),
                  );
              }}
            />
          </div>
          <label htmlFor="distance_1">{t("ds.distance_1")}</label>
        </div>
        <div className="height-10"></div>
        <div className="d-flex gap-2 align-items-center bodytext-lg">
          <div>
            <input
              id="distance_3"
              type="checkbox"
              checked={filters.categories_filter?.includes("distance::3000")}
              onChange={() => {
                if (filters.categories_filter?.includes("distance::3000"))
                  setFilters(updateCategoryFilter("distance", "", filters));
                else
                  setFilters(
                    updateCategoryFilter("distance", "distance::3000", filters),
                  );
              }}
            />
          </div>
          <label htmlFor="distance_3">{t("ds.distance_3")}</label>
        </div>
        <div className="height-10"></div>
        <div className="d-flex gap-2 align-items-center bodytext-lg">
          <div>
            <input
              id="distance_5"
              type="checkbox"
              checked={filters.categories_filter?.includes("distance::5000")}
              onChange={() => {
                if (filters.categories_filter?.includes("distance::5000"))
                  setFilters(updateCategoryFilter("distance", "", filters));
                else
                  setFilters(
                    updateCategoryFilter("distance", "distance::5000", filters),
                  );
              }}
            />
          </div>
          <label htmlFor="distance_5">{t("ds.distance_5")}</label>
        </div>
        <br />

        <div>
          <div
            className="horizontal-divider"
            style={{ margin: "26px 0" }}
          ></div>
          <FacilitiesSection filters={filters} setFilters={setFilters} />
        </div>
        <br />

        <div>
          <div className="horizontal-divider" style={{ margin: "0" }}></div>
          <div className="d-flex justify-content-center justify-content-md-end p-3">
            <Button
              text={t("ds.show_results_filter")}
              onClick={() => {
                // const params = Object.entries(filters)
                //   .map(
                //     ([key, value]) =>
                //       `${key}:${
                //         Array.isArray(value) ? value.join("|") : value
                //       }`,
                //   )
                //   .join(",");
                fireTagManagerEvent("filter_results", filters);

                onFilter(filters);
              }}
            />
          </div>
        </div>
        {isSmallScreen && (
          <>
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </div>
    </>
  );
}
