import { useTranslation } from "react-i18next";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import {
  BookingComSearchRegions,
  Destination,
  SearchParameters,
} from "../../../assets/Services/metasearch";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import { closeModal, openModal } from "../../../assets/Utils/modal";
import { DateRangePicker } from "react-date-range";
import { useCallback, useEffect, useRef, useState } from "react";
import { addDays } from "date-fns";
import { dateToSearchFormat } from "./utils";
import {
  datetimeRangeToString,
  datetimeToString,
  SMALL_SCREEN,
} from "../../../assets/Utils/generic";

import React from "react";
import { debounce } from "lodash";
import { useMediaQuery } from "react-responsive";
import { getLocaleLanguage } from "../../../assets/Services/i18next";
import { LANG } from "../../../config/Lang";
import Skeleton from "react-loading-skeleton";
import { it, es, enUS } from "date-fns/locale";
import { fireTagManagerEvent } from "../../../assets/Services/tagmanager";
import FiltersModal, { updateCategoryFilter } from "./FiltersModal";
import FilterTags from "./FilterTags";
import { useNavigate } from "react-router-dom";

interface CircularButtonProps {
  onClick?: () => void;
  children?: React.ReactNode; // Custom content (e.g., icons, text)
  className?: string; // Allow additional styles
}

const CircularButton: React.FC<CircularButtonProps> = ({
  onClick,
  children,
  className = "",
}) => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center cursor-pointer white-color black-background rounded-circle ${className}`}
      style={{ width: "25px", height: "25px", userSelect: "none" }} // Adjust size here
      onClick={onClick}
    >
      {children || <span>+</span>}
    </div>
  );
};

export const MIN_PRICE = 10;
export const MAX_PRICE = 1000;

export const search = (params: SearchParameters, navigate: Function) => {
  if (!params.dest_id || !params.departure_date || !params.arrival_date) return;

  [
    "property_type::204", // hotel
    "property_type::203", // hostel
    "property_type::205", // motel
    "property_type::208", // b&b
    "property_type::225", // capsule hotels
    "property_type::206", // resort
    "property_type::221", // lodges
    "property_type::226", // love hotels
  ].map((e) => {
    params = updateCategoryFilter(e, e, params);
  });

  params.sort_by = "bayesian_review_score";

  if (params.price_min && Number(params.price_min) <= MIN_PRICE)
    params.price_min = undefined;

  if (params.price_max && Number(params.price_max) >= MAX_PRICE)
    params.price_max = undefined;

  // keep only truthy query parameters
  const queryParamsFiltered = Object.entries(params)
    .filter(([_, value]) => value)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const queryString = new URLSearchParams(queryParamsFiltered).toString();
  const target = "/directsearch/search?" + queryString;
  //process.env.REACT_APP_FRONTEND_URI +

  navigate(target);
  // window.location.href = target;
};

const DestinationInput: React.FC<{
  setIsLocationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  setLocations: React.Dispatch<React.SetStateAction<any[]>>;
  params: SearchParameters;
  locations: any[];
  isLocationOpen: boolean;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  mode: "mobile" | "desktop";
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchCallbackDebounced: React.MutableRefObject<any>;
  locaitonRef: React.RefObject<HTMLDivElement>;
}> = ({
  setIsLocationOpen,
  setParams,
  setLocations,
  params,
  locations,
  isLocationOpen,
  isFocused,
  setIsFocused,
  mode,
  isLoading,
  setIsLoading,
  searchCallbackDebounced,
  locaitonRef,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="d-flex w100"
      style={{ padding: "10px 20px", position: "relative" }}
    >
      <div
        className="h100 w100 d-flex gap-2 cursor-pointer"
        onClick={() => {
          setIsLocationOpen(!isLocationOpen);
          setParams({ ...params, dest_label: "" });
          setLocations([]);

          document.getElementById("search-location-input")?.focus();
        }}
      >
        <div>
          <IconNew icon="location_on" style={{ fill: "lightgray" }} />
        </div>
        <div className="d-flex flex-column w100">
          <span className="fs-body-sm light dark-grey-color">
            {t("ds.destination")}
          </span>
          <>
            {/* Inline placeholder style injection */}
            <style>
              {`
        input#search-location-input::placeholder {
        color: ${!isFocused ? "black" : "gray"} !important;
        opacity: 1;
        }
        `}
            </style>

            <input
              autoComplete="off"
              id="search-location-input"
              style={{
                border: "unset",
                margin: "0",
                padding: "0",
                outline: "none", // Remove focus border
                background: "transparent", // Optional: Ensure no background styles
                color: "inherit", // Ensures input text inherits the parent's color
                fontSize: mode === "mobile" ? "16px !important" : "",
              }}
              placeholder={t("ds.destination_ph")}
              value={params?.dest_label}
              className={`${
                mode === "desktop" ? "fs-body-sm" : "fs-body-sm"
              } medium text-nowrap`}
              type="custom"
              onChange={(e) => {
                setParams({ ...params, dest_label: e.target.value });
                if (e.target.value.length >= 2) {
                  setIsLocationOpen(true);
                  setIsLoading(true);
                  searchCallbackDebounced.current(e.target.value);
                }
              }}
              onFocus={() => setIsFocused(true)} // Set focused state
              onBlur={() => setIsFocused(false)} // Reset focused state
            />
          </>
        </div>
      </div>

      {isLocationOpen && (locations.length > 0 || isLoading) ? (
        <>
          <div
            ref={locaitonRef} // Add ref here
            className="rounded"
            style={{
              position: "absolute",
              top: "105%",
              zIndex: 100,
              overflow: "hidden",
              border: "1px solid lightgray",
              boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
              background: "white",

              left: mode === "mobile" ? 0 : "unset",
              right: mode === "mobile" ? 0 : "unset",
            }}
          >
            {isLoading ? (
              <div
                style={{ width: "300px" }}
                className="d-flex gap-2 flex-column p-3"
              >
                <Skeleton width={100} />
                <Skeleton />
                <br />
                <Skeleton width={100} />
                <Skeleton />
                <br />
                <Skeleton width={100} />
                <Skeleton />
                <br />
              </div>
            ) : (
              <>
                <div
                  style={{ minWidth: "300px" }}
                  className="d-flex gap-2 flex-column p-3"
                >
                  {locations.map((l, key) => {
                    return (
                      <div
                        onClick={() => {
                          setParams({
                            ...params,
                            dest_label: l.label,
                            dest_id: l.dest_id,
                            search_type: l.dest_type,
                            latitude: "",
                            longitude: "",
                          });

                          setIsLocationOpen(false);
                        }}
                        key={key}
                        className="cursor-pointer d-flex gap-2"
                      >
                        <div className="mt-1">
                          <IconNew icon="location_on" />
                        </div>
                        <div>
                          <p className="fs-body-sm medium">
                            {l.city_name ? l.city_name : l.label}
                          </p>
                          <p className="fs-body-sm light">{l.label}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};

const DatesInput: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  mode: "mobile" | "desktop";
  isCalendarOpen: boolean;
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  calendarRef: React.RefObject<HTMLDivElement>;
  setRange: React.Dispatch<React.SetStateAction<any[]>>;
  range: any[];
  calendarLocale: any;
  isSmallScreen: boolean;
}> = ({
  setParams,
  params,
  mode,
  isCalendarOpen,
  setIsCalendarOpen,
  calendarRef,
  setRange,
  range,
  calendarLocale,
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="w100"
      style={{ padding: "10px 20px", position: "relative" }}
    >
      <div
        onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        className="d-flex gap-2 cursor-pointer h100"
      >
        <div className="d-flex gap-2 w100 h100">
          <div>
            <IconNew icon="calendar_month" style={{ fill: "lightgray" }} />
          </div>
          <div className="d-flex flex-column">
            <span className="fs-body-sm light dark-grey-color">
              {t("ds.checkin")}
            </span>
            <span
              style={{
                fontSize: mode === "mobile" ? "16px !important" : "",
              }}
              className={`${
                mode === "desktop" ? "fs-body-sm" : "fs-body-sm"
              } medium text-nowrap`}
            >
              {params.arrival_date
                ? datetimeToString(
                    params.arrival_date,
                    undefined,
                    isSmallScreen,
                  )
                : t("ds.checkin_ph")}
            </span>
          </div>
        </div>

        <div className="vertical-divider m-0" style={{ height: "unset" }}></div>

        <div className="d-flex gap-2 w100 h100">
          <div>
            <IconNew icon="calendar_month" style={{ fill: "lightgray" }} />
          </div>
          <div className="d-flex flex-column">
            <span className="fs-body-sm light dark-grey-color">
              {t("ds.checkout")}
            </span>
            <span
              style={{
                fontSize: mode === "mobile" ? "16px !important" : "",
              }}
              className={`${
                mode === "desktop" ? "fs-body-sm" : "fs-body-sm"
              } medium text-nowrap`}
            >
              {params.departure_date
                ? datetimeToString(
                    params.departure_date,
                    undefined,
                    isSmallScreen,
                  )
                : t("ds.checkout_ph")}
            </span>
          </div>
        </div>
      </div>

      {isCalendarOpen && (
        <>
          <div
            ref={calendarRef} // Add ref here
            className="rounded flex flex-column h100"
            style={
              mode === "desktop"
                ? {
                    position: "absolute",
                    top: "105%",
                    zIndex: 100,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                    minHeight: "400px",
                    minWidth: "550px",
                  }
                : {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                  }
            }
          >
            <div style={{ maxHeight: "78vh", overflowY: "auto" }}>
              <DateRangePicker
                preventSnapRefocus
                locale={calendarLocale}
                minDate={new Date()}
                className="rounded w100"
                onChange={(item) => {
                  setRange([item.selection as any]);

                  setParams({
                    ...params,
                    arrival_date: dateToSearchFormat(
                      item.selection.startDate as any,
                    ),
                    departure_date: dateToSearchFormat(
                      item.selection.endDate as any,
                    ),
                  });
                  if (
                    item.selection.startDate !== item.selection.endDate &&
                    mode === "desktop"
                  ) {
                    setIsCalendarOpen(false);
                  }
                }}
                months={2}
                ranges={range}
                direction={mode === "desktop" ? "horizontal" : "vertical"}
                staticRanges={[]}
                inputRanges={[]}
              />
              {mode === "mobile" && (
                <div
                  className="w100 p-3"
                  style={{ position: "fixed", bottom: 0 }}
                >
                  <Button
                    onClick={() => setIsCalendarOpen(false)}
                    className="w100"
                    text={t("ds.done")}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const GuestsInput: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  mode: "mobile" | "desktop";
  isGuestsOpen: boolean;
  setIsGuestsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  guestsRef: React.RefObject<HTMLDivElement>;
}> = ({
  setParams,
  params,
  mode,
  isGuestsOpen,
  setIsGuestsOpen,
  guestsRef,
}) => {
  const { t } = useTranslation();
  const [adults, setAdults] = useState(Number(params.adults ?? 2));
  useEffect(() => {
    setParams((p) => ({ ...p, adults: adults.toString() }));
  }, [adults, setParams]);

  const [children, setChildren] = useState<number[]>(
    params.children_age?.split(",").map((a) => Number(a)) ?? [],
  );
  useEffect(() => {
    setParams((p) => ({ ...p, children_age: children.join(",") }));
  }, [children, setParams]);

  return (
    <div
      className="w100"
      style={{ padding: "10px 20px", position: "relative" }}
    >
      <div
        className="d-flex gap-2 cursor-pointer h100 w100"
        onClick={() => setIsGuestsOpen(!isGuestsOpen)}
      >
        <div>
          <IconNew icon="people" style={{ fill: "lightgray" }} />
        </div>
        <div className="d-flex flex-column">
          <span className="fs-body-sm light dark-grey-color">
            {t("ds.guests")}
          </span>
          <span
            style={{
              fontSize: mode === "mobile" ? "16px !important" : "",
            }}
            className={`${
              mode === "desktop" ? "fs-body-sm" : "fs-body-sm"
            } medium text-nowrap`}
          >
            {adults ?? t("ds.guests_ph")} {t("ds.adults")}{" "}
            {children.length > 0 && (
              <>
                - {children.length} {t("ds.childs")}
              </>
            )}
          </span>
        </div>
      </div>

      {isGuestsOpen && (
        <>
          <div
            ref={guestsRef} // Add ref here
            className="rounded p-3"
            style={
              mode === "desktop"
                ? {
                    position: "absolute",
                    top: "105%",
                    zIndex: 100,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                  }
                : {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 100,
                    overflow: "hidden",
                    border: "1px solid lightgray",
                    boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
                    background: "white",
                  }
            }
          >
            {mode === "mobile" && <div className="height-100"></div>}

            <div
              style={{ width: mode === "desktop" ? "300px" : "unset" }}
              className="d-flex flex-column gap-3 w100"
            >
              <div className="w100 d-flex justify-content-between align-items-center">
                <span className="fs-body medium">{t("ds.guests_adults")}</span>

                <div className="d-flex align-items-center">
                  <CircularButton
                    onClick={() => {
                      setAdults((a) => (a > 1 ? a - 1 : 1));
                    }}
                  >
                    -
                  </CircularButton>
                  <span className="width-40 text-center fs-body medium">
                    {adults}
                  </span>
                  <CircularButton
                    onClick={() => {
                      setAdults((a) => a + 1);
                    }}
                  >
                    +
                  </CircularButton>
                </div>
              </div>
              <div className="w100 d-flex justify-content-between align-items-center">
                <span className="fs-body medium">{t("ds.guests_kids")}</span>

                <div className="d-flex align-items-center">
                  <CircularButton
                    onClick={() => {
                      setChildren((c) => c.slice(0, -1));
                    }}
                  >
                    -
                  </CircularButton>
                  <span className="width-40 text-center fs-body-lg medium">
                    {children.length}
                  </span>
                  <CircularButton
                    onClick={() => {
                      setChildren((c) => [...c, 10]);
                    }}
                  >
                    +
                  </CircularButton>
                </div>
              </div>
            </div>

            {mode === "mobile" && <div className="height-25"></div>}

            {mode === "mobile" && (
              <div className="w100 p-3">
                <Button
                  onClick={() => setIsGuestsOpen(false)}
                  className="w100"
                  text={t("ds.done")}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const MobileMinified: React.FC<{
  params: SearchParameters;
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  showFilters: boolean;
  setIsMobileMinified?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ params, showFilters, setIsMobileMinified, setParams }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div id="searchbar" className="d-flex flex-column gap-2">
      <div
        className="d-flex gap-2"
        onClick={() => setIsMobileMinified?.((v) => !v)}
      >
        <div
          style={{
            borderRadius: "8px",
            boxShadow: "4px 3px 10px 1px rgba(0, 0, 0, 0.05)",
            userSelect: "none",
            border: "1px solid lightgray",
            maxWidth: "calc(100% - 48px)",
          }}
          className="w100 d-flex align-items-center gap-2 p-3 py-2 cursor-pointer white-background"
        >
          <IconNew icon="search" />
          <span className="fs-body-sm medium text-truncate">
            {params?.dest_label?.split(",")[0] ?? ""}
            {", "}
            {datetimeRangeToString(
              params?.arrival_date,
              params?.departure_date,
              -new Date().getTimezoneOffset(),
              false,
            )}
            , {params?.adults ?? 2} {t("ds.guests")}
          </span>
        </div>

        {showFilters && (
          <Button
            size="sm"
            variant="light"
            icon="menu"
            onClick={(e) => {
              e.stopPropagation();
              openModal({
                content: (
                  <FiltersModal
                    searchParameters={params}
                    onFilter={(f) => {
                      search({ ...params, ...f }, navigate);
                      closeModal();
                    }}
                  />
                ),
              });
            }}
          />
        )}
      </div>
      <FilterTags params={params} />
    </div>
  );
};

const MobileVersion: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  isCalendarOpen: boolean;
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  calendarRef: React.RefObject<HTMLDivElement>;
  setRange: React.Dispatch<React.SetStateAction<any[]>>;
  range: any[];
  calendarLocale: Locale;
  isLocationOpen: boolean;
  setIsLocationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locations: Destination[];
  setLocations: React.Dispatch<React.SetStateAction<Destination[]>>;
  isGuestsOpen: boolean;
  setIsGuestsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  guestsRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchCallbackDebounced: React.MutableRefObject<any>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  locaitonRef: React.RefObject<HTMLDivElement>;
  isSmallScreen: boolean;
  setIsMobileMinified?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  setParams,
  params,
  isCalendarOpen,
  setIsCalendarOpen,
  calendarRef,
  setRange,
  range,
  calendarLocale,
  isLocationOpen,
  setIsLocationOpen,
  locations,
  setLocations,
  isGuestsOpen,
  setIsGuestsOpen,
  guestsRef,
  isLoading,
  setIsLoading,
  searchCallbackDebounced,
  isFocused,
  setIsFocused,
  locaitonRef,
  isSmallScreen,
  setIsMobileMinified,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      style={{
        ...(window.location.pathname.includes("/directsearch")
          ? { position: "fixed", zIndex: 10, width: "90%" }
          : {}),
      }}
      className="d-flex flex-column p-3 gap-2 white-background rounded-3"
    >
      <div
        style={{
          borderRadius: "8px",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
        }}
        className="border "
      >
        <DestinationInput
          setIsLocationOpen={setIsLocationOpen}
          setParams={setParams}
          setLocations={setLocations}
          params={params}
          locations={locations}
          isLocationOpen={isLocationOpen}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          mode="mobile"
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          searchCallbackDebounced={searchCallbackDebounced}
          locaitonRef={locaitonRef}
        />
      </div>
      <div
        style={{
          borderRadius: "8px",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
        }}
        className="border "
      >
        <DatesInput
          setParams={setParams}
          params={params}
          mode="mobile"
          isCalendarOpen={isCalendarOpen}
          setIsCalendarOpen={setIsCalendarOpen}
          calendarRef={calendarRef}
          setRange={setRange}
          range={range}
          calendarLocale={calendarLocale}
          isSmallScreen={isSmallScreen}
        />
      </div>
      <div
        style={{
          borderRadius: "8px",
          boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
        }}
        className="border "
      >
        <GuestsInput
          setParams={setParams}
          params={params}
          mode="mobile"
          isGuestsOpen={isGuestsOpen}
          setIsGuestsOpen={setIsGuestsOpen}
          guestsRef={guestsRef}
        />
      </div>
      <Button
        onClick={() => {
          search(params, navigate);
          setIsMobileMinified?.(true);
        }}
        text={t("ds.search")}
      />
    </div>
  );
};

const DesktopVersion: React.FC<{
  setParams: React.Dispatch<React.SetStateAction<SearchParameters>>;
  params: SearchParameters;
  isCalendarOpen: boolean;
  setIsCalendarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  calendarRef: React.RefObject<HTMLDivElement>;
  setRange: React.Dispatch<React.SetStateAction<any[]>>;
  range: any[];
  calendarLocale: Locale;
  isLocationOpen: boolean;
  setIsLocationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  locations: Destination[];
  setLocations: React.Dispatch<React.SetStateAction<Destination[]>>;
  isGuestsOpen: boolean;
  setIsGuestsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  guestsRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchCallbackDebounced: React.MutableRefObject<any>;
  isFocused: boolean;
  setIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
  locaitonRef: React.RefObject<HTMLDivElement>;
  isSmallScreen: boolean;
  showFilters: boolean;
}> = ({
  setParams,
  params,
  isCalendarOpen,
  setIsCalendarOpen,
  calendarRef,
  setRange,
  range,
  calendarLocale,
  isLocationOpen,
  setIsLocationOpen,
  locations,
  setLocations,
  isGuestsOpen,
  setIsGuestsOpen,
  guestsRef,
  isLoading,
  setIsLoading,
  searchCallbackDebounced,
  isFocused,
  setIsFocused,
  locaitonRef,
  isSmallScreen,
  showFilters,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const openFiltersModal = useCallback(() => {
    fireTagManagerEvent("open_filter");
    openModal({
      content: (
        <FiltersModal
          searchParameters={params}
          onFilter={(f) => {
            search({ ...params, ...f }, navigate);
            closeModal();
          }}
        />
      ),
    });
  }, [params, search]);

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center justify-content-center w100 gap-2">
        <div
          style={{
            border: "1px solid lightgray",
            boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="w100 rounded-3 white-background d-flex"
        >
          {/* location */}
          <DestinationInput
            setIsLocationOpen={setIsLocationOpen}
            setParams={setParams}
            setLocations={setLocations}
            params={params}
            locations={locations}
            isLocationOpen={isLocationOpen}
            isFocused={isFocused}
            setIsFocused={setIsFocused}
            mode="desktop"
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            searchCallbackDebounced={searchCallbackDebounced}
            locaitonRef={locaitonRef}
          />
          <div
            className="vertical-divider m-0"
            style={{ height: "unset" }}
          ></div>
          {/* dates */}
          <DatesInput
            setParams={setParams}
            params={params}
            mode="desktop"
            isCalendarOpen={isCalendarOpen}
            setIsCalendarOpen={setIsCalendarOpen}
            calendarRef={calendarRef}
            setRange={setRange}
            range={range}
            calendarLocale={calendarLocale}
            isSmallScreen={isSmallScreen}
          />
          <div
            className="vertical-divider m-0"
            style={{ height: "unset" }}
          ></div>
          {/* guests */}
          <GuestsInput
            setParams={setParams}
            params={params}
            mode="desktop"
            isGuestsOpen={isGuestsOpen}
            setIsGuestsOpen={setIsGuestsOpen}
            guestsRef={guestsRef}
          />
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ padding: "10px" }}
          >
            <Button
              textClass="fs-body-sm medium"
              onClick={() => search(params, navigate)}
              text={t("ds.search")}
            />
          </div>
        </div>
        {showFilters && (
          <Button
            textClass="fs-body-sm medium"
            text={t("ds.filters")}
            variant="light"
            icon="menu"
            onClick={openFiltersModal}
          />
        )}
      </div>
      <FilterTags params={params} />
    </div>
  );
};

export default function Search({
  showFilters,
  searchParameters,
  mode = "desktop",
  isMobileMinified,
  setIsMobileMinified,
}: {
  showFilters?: boolean;
  searchParameters?: SearchParameters;
  mode?: "mobile" | "desktop";
  isMobileMinified?: boolean;
  setIsMobileMinified?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();

  const [params, setParams] = useState<SearchParameters>(
    searchParameters || {},
  );
  useEffect(() => {
    if (searchParameters) setParams(searchParameters);
  }, [searchParameters]);

  const [calendarLocale, setCalendarLocale] = useState<any>();

  const isSmallScreen = useMediaQuery({ query: SMALL_SCREEN });

  useEffect(() => {
    const initAsync = async () => {
      const l = await getLocaleLanguage();

      let langValue: LANG | undefined = l as LANG | undefined;

      let calendarLocale = enUS;
      if (langValue === LANG.it) calendarLocale = it;
      if (langValue === LANG.es) calendarLocale = es;
      setCalendarLocale(calendarLocale);

      if (langValue === LANG.en) langValue = "en-gb" as any;

      setParams({ ...params, languagecode: langValue });
    };
    initAsync();
  }, [t]);

  const [isFocused, setIsFocused] = useState(false);

  const [locations, setLocations] = useState<Destination[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [range, setRange] = useState([
    {
      startDate: searchParameters?.arrival_date
        ? new Date(searchParameters?.arrival_date)
        : new Date(),
      endDate: searchParameters?.departure_date
        ? new Date(searchParameters?.departure_date)
        : addDays(new Date(), 1),
      key: "selection",
    },
  ]);

  const searchLocation = async (query: string) => {
    setIsLoading(true);
    try {
      const data = await BookingComSearchRegions({
        query,
      });
      setLocations(data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const searchCallbackDebounced = useRef(debounce(searchLocation, 1000));

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [shouldOpenCalendar, setShouldOpenCalendar] = useState(
    !searchParameters?.dest_id,
  );

  useEffect(() => {
    if (shouldOpenCalendar && !!params.dest_id) {
      setShouldOpenCalendar(false);
      setIsCalendarOpen(true);
    }
  }, [params.dest_id, shouldOpenCalendar]);

  const calendarRef = useRef<HTMLDivElement>(null);
  const guestsRef = useRef<HTMLDivElement>(null);
  const locaitonRef = useRef<HTMLDivElement>(null);

  // Handle clicks outside the calendar
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsCalendarOpen(false);
      }
    }

    if (isCalendarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCalendarOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        guestsRef.current &&
        !guestsRef.current.contains(event.target as Node)
      ) {
        setIsGuestsOpen(false);
      }
    }

    if (isGuestsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isGuestsOpen]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        locaitonRef.current &&
        !locaitonRef.current.contains(event.target as Node)
      ) {
        setIsLocationOpen(false);
      }
    }

    if (isGuestsOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isLocationOpen]);

  if (mode === "mobile") {
    if (isMobileMinified)
      return (
        <MobileMinified
          params={params}
          setParams={setParams}
          showFilters={showFilters as any}
          setIsMobileMinified={setIsMobileMinified}
        />
      );
    return (
      <MobileVersion
        setIsMobileMinified={setIsMobileMinified}
        params={params}
        setParams={setParams}
        locations={locations}
        setLocations={setLocations}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        isFocused={isFocused}
        setIsFocused={setIsFocused}
        isCalendarOpen={isCalendarOpen}
        setIsCalendarOpen={setIsCalendarOpen}
        isGuestsOpen={isGuestsOpen}
        setIsGuestsOpen={setIsGuestsOpen}
        isLocationOpen={isLocationOpen}
        setIsLocationOpen={setIsLocationOpen}
        calendarRef={calendarRef}
        guestsRef={guestsRef}
        locaitonRef={locaitonRef}
        range={range}
        setRange={setRange}
        calendarLocale={calendarLocale}
        searchCallbackDebounced={searchCallbackDebounced}
        isSmallScreen={isSmallScreen}
      />
    );
  }

  return (
    <DesktopVersion
      params={params}
      setParams={setParams}
      locations={locations}
      setLocations={setLocations}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      isFocused={isFocused}
      setIsFocused={setIsFocused}
      isCalendarOpen={isCalendarOpen}
      setIsCalendarOpen={setIsCalendarOpen}
      isGuestsOpen={isGuestsOpen}
      setIsGuestsOpen={setIsGuestsOpen}
      isLocationOpen={isLocationOpen}
      setIsLocationOpen={setIsLocationOpen}
      calendarRef={calendarRef}
      guestsRef={guestsRef}
      locaitonRef={locaitonRef}
      range={range}
      setRange={setRange}
      calendarLocale={calendarLocale}
      searchCallbackDebounced={searchCallbackDebounced}
      isSmallScreen={isSmallScreen}
      showFilters={showFilters as any}
    />
  );
}
