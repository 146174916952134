import { useCallback, useMemo } from "react";
import { SearchParameters } from "../../../assets/Services/metasearch";
import Chip from "../../../assets/TakyonDesignSystem/components/Chip/Chip";
import { updateCategoryFilter } from "./FiltersModal";
import { useTranslation } from "react-i18next";
import ScrollContainer from "../../../assets/TakyonDesignSystem/components/ScrollContainer/ScrollContainer";
import { MAX_PRICE, MIN_PRICE, search } from "./Search";
import { useNavigate } from "react-router-dom";

type Filter = { filter: string; value: any };

export default function FilterTags({ params }: { params: SearchParameters }) {
  const filters = useMemo(() => decodeFilters(params), [params]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onDelete = useCallback(
    (f: Filter) => {
      if (Object.keys(params).includes(f.filter))
        search({ ...params, [f.filter]: undefined }, navigate);
      else search(updateCategoryFilter(f.filter, "", params, true), navigate);
    },
    [params]
  );

  const getFilterLabel = useCallback(
    (f: Filter) => {
      if (f.filter === "price_min") {
        return `${t("ds.filter_price_min")}: €${f.value}`;
      } else if (f.filter === "price_max") {
        return `${t("ds.filter_price_max")}: €${f.value}`;
      } else if (f.filter === "certified_only") return t("ds.certified_only");
      else if (f.filter === "includes_benefits") {
        return t("ds.includes_benefits");
      } else if (f.filter.includes("reviewscorebuckets")) {
        const score = f.value.split("::")[1];
        return `${t("ds.reviewscorebuckets")}: ${t(`ds.score_${score}`)}`;
      } else if (f.filter.includes("distance")) {
        const distance = Math.round(Number(f.value.split("::")[1]) / 1000);
        return t(`ds.distance_${distance}`);
      } else if (f.filter.includes("hotelfacility")) {
        const facility_number = Number(f.value.split("::")[1]);
        return t(`facilities.${facility[facility_number]}`);
      }

      return "";
    },
    [t]
  );

  return (
    <ScrollContainer
      isLightTheme={true}
      content={
        <>
          {filters.map((f) => (
            <Chip label={getFilterLabel(f)} onDelete={() => onDelete(f)} />
          ))}
        </>
      }
    ></ScrollContainer>
  );
}

function decodeFilters(params: SearchParameters): Filter[] {
  const filters: Filter[] = [];

  const min_pirce = Number(params.price_min);
  if (min_pirce > MIN_PRICE)
    filters.push({ filter: "price_min", value: min_pirce });
  const max_pirce = Number(params.price_max);
  if (max_pirce < MAX_PRICE)
    filters.push({ filter: "price_max", value: max_pirce });

  if (params.certified_only === "true")
    filters.push({ filter: "certified_only", value: true });
  if (params.includes_benefits === "true")
    filters.push({ filter: "includes_benefits", value: true });

  const review_score_match = params.categories_filter?.match(
    /reviewscorebuckets::(\d+)/g
  );
  if (review_score_match)
    filters.push(...review_score_match.map((m) => ({ filter: m, value: m })));

  const distance_match = params.categories_filter?.match(/distance::(\d+)/g);
  if (distance_match)
    filters.push(...distance_match.map((m) => ({ filter: m, value: m })));

  const facility_match =
    params.categories_filter?.match(/hotelfacility::(\d+)/g) || [];
  if (facility_match)
    filters.push(...facility_match.map((m) => ({ filter: m, value: m })));

  return filters;
}

export const facility: Record<number, string> = {
  2: "parking",
  3: "restaurant",
  4: "pets_allowed",
  5: "room_service",
  8: "24_hour_front_desk",
  11: "fitness_centre",
  16: "non_smoking_rooms",
  17: "airport_shuttle",
  25: "facilities_for_disabled_guests",
  28: "family_rooms",
  46: "free_parking",
  54: "spa_and_wellness_centre",
  72: "bbq_facilities",
  107: "free_wifi",
  139: "airport_shuttle_free",
  182: "electric_vehicle_charging_station",
  433: "swimming_pool",
};
